<template>
  <div class="w-full h-screen bg-fade">
    <div class="pt-20 mb-5">
      <img class="object-contain h-16 w-full" src="../assets/mle-logo-light.png" />
    </div>
    <div class="text-center">
      <div class="rounded-lg bg-gray-100 inline-block py-6 px-8 text-left ">
        <p v-if="showError" id="error">Username or Password is incorrect</p>
        <form @submit.prevent="submit">
          <div class="mb-5">
            <label class="block mb-1">Username</label>
            <input class="px-3 py-2 w-80 shadow focus:outline-none focus:ring focus:ring-green-400" type="text" v-model="form.username" />
          </div>
          <div class="mb-5">
            <label class="block mb-1">Password</label>
            <input class="px-3 py-2 w-80 shadow focus:outline-none focus:ring focus:ring-green-400" type="password" v-model="form.password" />
          </div>
          <div class="mb-5">
            <button class="bg-green w-full rounded-md text-white p-1 shadow" type="submit">Login</button>
          </div>
          <div class="text-right">
            <router-link to="/forgot-password" class="text-green text-sm hover:underline">Forgot password?</router-link>
          </div>
        </form>
      </div>
      <h2 class="text-xl text-green my-5 font-bold">REPRESENTATIVE CENTER</h2>
    </div>
  </div>
</template>

<style lang="scss">
.bg-fade {
  background: rgb(51, 51, 51);
  background: linear-gradient(
    90deg,
    rgba(51, 51, 51, 1) 0%,
    rgba(34, 34, 34, 1) 100%
  );
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      try {
        await this.LogIn(User);

        let path = this.$route.query.referrer ?? "/";
        let link = this.$router.resolve({ path: path });
        if (link && link.matched.length > 0) {
          this.$router.push(path);
        } else {
          this.$router.push("/");
        }

        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>
